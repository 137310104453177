export const HOTELS_SEEN_SCHEMA_NAME = 'hotels.SearchResultSeenEvent';
export const HOTELS_SEEN_MINIEVENT_NAME = 'HotelsSearchResultSeen';

export const ACTION_TYPE = {
  UNSET_ACTION_TYPE: 'UNSET_ACTION_TYPE',

  PAGE_LOAD: 'PAGE_LOAD',
  SEARCH_STARTED: 'SEARCH_STARTED',
  SEARCH_FINISHED: 'SEARCH_FINISHED',
  DAY_VIEW_SEARCH: 'DAY_VIEW_SEARCH',
  HOME_PAGE_SEARCH: 'HOME_PAGE_SEARCH',
  DAY_VIEW_HOTEL_CARD_CLICKED: 'DAY_VIEW_HOTEL_CARD_CLICKED',
  DAY_VIEW_HOTEL_CARD_DBOOK_CLICKED: 'DAY_VIEW_HOTEL_CARD_DBOOK_CLICKED',
  DAY_VIEW_HOTEL_CARD_META_CLICKED: 'DAY_VIEW_HOTEL_CARD_META_CLICKED',
  DAY_VIEW_META_REDIRECT: 'DAY_VIEW_META_REDIRECT',
  DAY_VIEW_FILTERS_USED: 'DAY_VIEW_FILTERS_USED',
  DAY_VIEW_SORT_USED: 'DAY_VIEW_SORT_USED',
  SEARCH_RESULT_SELECTED: 'SEARCH_RESULT_SELECTED',
  PRICES_LOADED: 'PRICES_LOADED',
  ROOM_INFO_MODAL_BUTTON_CLICKED: 'ROOM_INFO_MODAL_BUTTON_CLICKED',
  ROOM_INFO_MODAL_CLICKED: 'ROOM_INFO_MODAL_CLICKED',
  DIRECT_MODAL_CLICKED: 'DIRECT_MODAL_CLICKED',
  PRICE_BREAK_DOWN_MODAL_CLICKED: 'PRICE_BREAK_DOWN_MODAL_CLICKED',
  PRICE_DBOOK_SELECTED: 'PRICE_DBOOK_SELECTED',
  PRICE_META_SELECTED: 'PRICE_META_SELECTED',
  PRICE_SELECTED: 'PRICE_SELECTED',
  MOST_POPULAR_RATE_CARD_CLICKED: 'MOST_POPULAR_RATE_CARD_CLICKED',
  BEST_OFFER_RATE_CARD_CLICKED: 'BEST_OFFER_RATE_CARD_CLICKED',
  MAIN_CTA_BUTTON_CLICKED: 'MAIN_CTA_BUTTON_CLICKED',
  MOST_POPULAR_PRICES_MODAL_BUTTON_CLICKED:
    'MOST_POPULAR_PRICES_MODAL_BUTTON_CLICKED',
  RATE_BOOK_BUTTON_CLICKDED: 'RATE_BOOK_BUTTON_CLICKDED',
  SPECIFIC_RATE_BOOK_DBOOK_BUTTON_CLICKED:
    'SPECIFIC_RATE_BOOK_DBOOK_BUTTON_CLICKED',
  DBOOK_CLICK: 'DBOOK_CLICK',
  AVAILABLE_DEALS_CLICKED: 'AVAILABLE_DEALS_CLICKED',
  MOST_POPULAR_RATE_CARD_DBOOK_CLICKED: 'MOST_POPULAR_RATE_CARD_DBOOK_CLICKED',
  MOST_POPULAR_RATE_CARD_META_CLICKED: 'MOST_POPULAR_RATE_CARD_META_CLICKED',
  MOST_POPULAR_PRICES_MODAL_SELECT_ROOM_BUTTON_CLICKED:
    'MOST_POPULAR_PRICES_MODAL_SELECT_ROOM_BUTTON_CLICKED',
  BEST_OFFER_DBOOK_RATE_CARD_CLICKED: 'BEST_OFFER_DBOOK_RATE_CARD_CLICKED',
  BEST_OFFER_META_RATE_CARD_CLICKED: 'BEST_OFFER_META_RATE_CARD_CLICKED',
  MAIN_CTA_DBOOK_BUTTON_CLICKED: 'MAIN_CTA_DBOOK_BUTTON_CLICKED',
  MAIN_CTA_META_BUTTON_CLICKED: 'MAIN_CTA_META_BUTTON_CLICKED',
  MOST_POPULAR_PRICES_MODAL_GO_TO_SITE_BUTTON_CLICKED:
    'MOST_POPULAR_PRICES_MODAL_GO_TO_SITE_BUTTON_CLICKED',
  CHECKOUT_FORM_BOOK_BUTTON_CLICKED: 'CHECKOUT_FORM_BOOK_BUTTON_CLICKED',
  YOUR_DETAILS_NEXT_BUTTON_CLICKED: 'YOUR_DETAILS_NEXT_BUTTON_CLICKED',
  HOTELS_HOME_PAGE_LOAD: 'HOTELS_HOME_PAGE_LOAD',
  HOTELS_RESULTS_PAGE_LOAD: 'HOTELS_RESULTS_PAGE_LOAD',
  HOTELS_DBOOK_PAGE_LOAD: 'HOTELS_DBOOK_PAGE_LOAD',
  HOTELS_PRESALE_PAGE_LOAD: 'HOTELS_PRESALE_PAGE_LOAD',
  HOTELS_IN_CITY_PAGE_LOAD: 'HOTELS_IN_CITY_PAGE_LOAD',
  STAR_HOTELS_IN_CITY_PAGE_LOAD: 'STAR_HOTELS_IN_CITY_PAGE_LOAD',
  HOTELS_IN_NEIGHBOURHOOD_PAGE_LOAD: 'HOTELS_IN_NEIGHBOURHOOD_PAGE_LOAD',
  HOTELS_NEAR_PLACE_PAGE_LOAD: 'HOTELS_NEAR_PLACE_PAGE_LOAD',
  HOTELS_DETAILS_PAGE_LOAD: 'HOTELS_DETAILS_PAGE_LOAD',
  DAY_VIEW_HOTEL_RECOMMEND_CARD_CLICKED:
    'DAY_VIEW_HOTEL_RECOMMEND_CARD_CLICKED',
  DAY_VIEW_HOTEL_RECOMMEND_TAG_CLICKED: 'DAY_VIEW_HOTEL_RECOMMEND_TAG_CLICKED',
  DAY_VIEW_HOTEL_RECOMMEND_CLOSE_CLICKED:
    'DAY_VIEW_HOTEL_RECOMMEND_CLOSE_CLICKED',
  VIEWED_HOTEL_CITY_FILTER_CLICK: 'VIEWED_HOTEL_CITY_FILTER_CLICK',
  VIEWED_HOTEL_VIEW_ALL_CLICK: 'VIEWED_HOTEL_VIEW_ALL_CLICK',
  VIEWED_HOTEL_CLEAR_MODAL_CLICK: 'VIEWED_HOTEL_CLEAR_MODAL_CLICK',
  VIEWED_HOTEL_STAY_EDIT: 'VIEWED_HOTEL_STAY_EDIT',
  VIEWED_HOTEL_VIEW_ENTRANCE_CLICK: 'VIEWED_HOTEL_VIEW_ENTRANCE_CLICK',
  VIEWED_HOTEL_CLEAR_CLICK: 'VIEWED_HOTEL_CLEAR_CLICK',
  VIEWED_HOTEL_CLEAR_CANCEL_CLICK: 'VIEWED_HOTEL_CLEAR_CANCEL_CLICK',
  VIEWED_HOTEL_HIDE_CLICK: 'VIEWED_HOTEL_HIDE_CLICK',
  VIEWED_HOTEL_SHOW_CLICK: 'VIEWED_HOTEL_SHOW_CLICK',
  VIEWED_HOTELS_PAGE_LOAD: 'VIEWED_HOTELS_PAGE_LOAD',
  DETAIL_PAGE_NEARBY_MAP_CLICK: 'DETAIL_PAGE_NEARBY_MAP_CLICK',
  MWEB_NEW_DAY_VIEW_MAP_CLICK: 'MWEB_NEW_DAY_VIEW_MAP_CLICK',
  DAY_VIEW_PAGINATION_CLICK: 'DAY_VIEW_PAGINATION_CLICK',
  DAY_VIEW_MAP_PANEL_CLICK: 'DAY_VIEW_MAP_PANEL_CLICK',
  HOTEL_DETAIL_ADDRESS_CLICK: 'HOTEL_DETAIL_ADDRESS_CLICK',
  HOTEL_DETAIL_RECOMMEND_HOTEL_CHIP_CLICK:
    'HOTEL_DETAIL_RECOMMEND_HOTEL_CHIP_CLICK',
  DAY_VIEW_MAP_SEARCH_CITY_CHANGE: 'DAY_VIEW_MAP_SEARCH_CITY_CHANGE',
  SEARCH_SYNC_MAP_CHECKBOX_CLICK: 'SEARCH_SYNC_MAP_CHECKBOX_CLICK',
  DAY_VIEW_MAP_SEARCH_THIS_AREA_CLICK: 'DAY_VIEW_MAP_SEARCH_THIS_AREA_CLICK',
  FIND_THESE_GREAT_DEALS_RENDERED: 'FIND_THESE_GREAT_DEALS_RENDERED',
  FIND_THESE_GREAT_DEALS_CLICKED: 'FIND_THESE_GREAT_DEALS_CLICKED',
  DETAILS_CLOSE_POIS_CARD_CLICKED: 'DETAILS_CLOSE_POIS_CARD_CLICKED',
  DETAILS_RATES_FILTER_CLICK: 'DETAILS_RATES_FILTER_CLICK',
  AUTO_SUGGEST_POIS_RENDER: 'AUTO_SUGGEST_POIS_RENDER',
  AUTO_SUGGEST_POIS_CLICK: 'AUTO_SUGGEST_POIS_CLICK',
  AUTO_SUGGEST_RECENT_SEARCH_RENDER: 'AUTO_SUGGEST_RECENT_SEARCH_RENDER',
  VIEW_BUTTON_CLICKED: 'VIEW_BUTTON_CLICKED',
  BASE_ROOM_TYPE_FILTER_CLICKED: 'BASE_ROOM_TYPE_FILTER_CLICKED',
  SEARCH_RESULT_LOADED: 'SEARCH_RESULT_LOADED',
  BOOKING_PROCESS_STATE: 'BOOKING_PROCESS_STATE',
  BOOKING_PERFORMED: 'BOOKING_PERFORMED',
  MAP_ACTIVE_DAY_VIEW: 'MAP_ACTIVE_DAY_VIEW',
  MAP_MOVING_DAY_VIEW: 'MAP_MOVING_DAY_VIEW',
  POI_MENU_OPEN_DAY_VIEW: 'POI_MENU_OPEN_DAY_VIEW',
  POI_MENU_SELECT_DAY_VIEW: 'POI_MENU_SELECT_DAY_VIEW',
  POI_SEARCH_DAY_VIEW: 'POI_SEARCH_DAY_VIEW',
  VIEWED_HOTELS_DATA_LOADED: 'VIEWED_HOTELS_DATA_LOADED',
  PRICES_LOADED_META_ONLY: 'PRICES_LOADED_META_ONLY',
  PRICES_LOADED_DBOOK_ONLY: 'PRICES_LOADED_DBOOK_ONLY',
  PRICES_LOADED_HYBRID: 'PRICES_LOADED_HYBRID',
  HOTEL_PRICES_STATUS: 'HOTEL_PRICES_STATUS',
  MAP_HOTEL_MARKER_CLICK: 'MAP_HOTEL_MARKER_CLICK',
  UNIFIED_PAYMENT_INTEGRATION: 'UNIFIED_PAYMENT_INTEGRATION',
  LOGIN_DEAL_PROCESS: 'LOGIN_DEAL_PROCESS',
  PRE_CHECK_STATE: 'PRE_CHECK_STATE',
};

export const DEVICE = {
  UNSET_DEVICE: 'UNSET_DEVICE',
  TABLET: 'TABLET_WEB',
  DESKTOP: 'DESKTOP_WEB',
  MOBILE: 'MOBILE_WEB',
};

export const ENTITY_TYPE = {
  UNSET_ENTITY_TYPE: 'UNSET_ENTITY_TYPE',
  HOTEL: 'SPECIFIC_HOTEL',
  CITY: 'CITY',
  DISTRICT: 'DISTRICT',
  NATION: 'NATION',
  TERRITORY: 'TERRITORY',
  ISLAND: 'ISLAND',
  OCEAN: 'OCEAN',
  REGION: 'REGION',
  FIRSTLEVELNATIONADMINISTRATIVEDIVISION: 'FIRST_ADMINISTRATIVE_DIVISION',
  SECONDLEVELNATIONADMINISTRATIVEDIVISION: 'SECOND_ADMINISTRATIVE_DIVISION',
  THIRDLEVELNATIONADMINISTRATIVEDIVISION: 'THIRD_ADMINISTRATIVE_DIVISION',
  AMUSEMENTPARK: 'AMUSEMENT_PARK',
  BEACH: 'BEACH',
  BUSSTATION: 'BUS_STATION',
  CAMPGROUND: 'CAMPGROUND',
  CARPARKINDOORS: 'CAR_PARK_INDOORS',
  CARPARKOUTDOORS: 'CAR_PARK_OUTDOORS',
  CARPARK: 'CAR_PARK',
  CARRENTAL: 'CAR_RENTAL',
  CASINOBUILDING: 'CASINO_BUILDING',
  CINEMAHALL: 'CINEMA_HALL',
  CONCERTHALL: 'CONCERT_HALL',
  CONVENTIONHALL: 'CONVENTION_HALL',
  COURTHOUSE: 'COURTHOUSE',
  CULTURALCENTERBUILDING: 'CULTURAL_CENTER_BUILDING',
  EMBASSYBUILDING: 'EMBASSY_BUILDING',
  EXHIBITIONCENTERBUILDING: 'EXHIBITION_CENTER_BUILDING',
  FRONTIERCROSSING: 'FRONTIER_CROSSING',
  GOLFCOURSE: 'GOLF_COURSE',
  GOVERNMENTBUILDING: 'GOVERNMENT_BUILDING',
  HARBOR: 'HARBOR',
  HISTORICSITE: 'HISTORIC_SITE',
  HOSPITAL: 'HOSPITAL',
  LEISURECENTERBUILDING: 'LEISURE_CENTER_BUILDING',
  LIBRARY: 'LIBRARY',
  MONUMENTLANDMARK: 'MONUMENT_LANDMARK',
  MOUNTAINPASS: 'MOUNTAIN_PASS',
  MOUNTAINPEAK: 'MOUNTAIN_PEAK',
  MUSEUM: 'MUSEUM',
  OPERAHALL: 'OPERA_HALL',
  PARK: 'PARK',
  RELIGIOUSBUILDING: 'RELIGIOUS_BUILDING',
  SHOPPINGMALL: 'SHOPPING_MALL',
  SKISTATION: 'SKI_STATION',
  SPORTSFACILITY: 'SPORTS_FACILITY',
  STADIUM: 'STADIUM',
  THEATREHALL: 'THEATRE_HALL',
  TOURISTATTRACTION: 'TOURIST_ATTRACTION',
  TOURISTINFORMATIONOFFICE: 'TOURIST_INFORMATION_OFFICE',
  TRAINSTATION: 'TRAIN_STATION',
  TRANSITWAYPLAZA: 'TRANSITWAY_PLAZA',
  METROSTATION: 'METRO_STATION',
  UNIVERSITYBUILDING: 'UNIVERSITY_BUILDING',
  ZOO: 'ZOO',
  ARTCENTER: 'ART_CENTER',
  ARTGALLERY: 'ART_GALLERY',
  BRIDGE: 'BRIDGE',
  CANYON: 'CANYON',
  CASTLE: 'CASTLE',
  CAVE: 'CAVE',
  DESERT: 'DESERT',
  FARM: 'FARM',
  FERRISWHEEL: 'FERRIS_WHEEL',
  FILMSTUDIO: 'FILM_STUDIO',
  FOREST: 'FOREST',
  FOUNTAIN: 'FOUNTAIN',
  GARDEN: 'GARDEN',
  GEOPARK: 'GEOPARK',
  GRASSLAND: 'GRASSLAND',
  HOTSPRING: 'HOT_SPRING',
  LAKE: 'LAKE',
  MODERNARCHITECTURE: 'MODERN_ARCHITECTURE',
  NATURERESERVE: 'NATURE_RESERVE',
  RIVER: 'RIVER',
  SCHOOL: 'SCHOOL',
  SPRING: 'SPRING',
  VOLCANO: 'VOLCANO',
  WATERFALL: 'WATERFALL',
  WETLAND: 'WETLAND',
  WORKSHOP: 'WORKSHOP',
};

export const AUTO_SUGGEST_POI_LIST = [
  'poi_id_one',
  'poi_id_two',
  'poi_id_three',
  'poi_id_four',
  'poi_id_five',
];

export const RESULT_TYPE = {
  UNSET_RESULT_TYPE: 'UNSET_RESULT_TYPE',
  AUTO_SUGGEST: 'AUTO_SUGGEST',
  POPULAR: 'POPULAR',
  RECENT: 'RECENT',
  CURRENT_LOCATION: 'CURRENT_LOCATION',
};

export const HOTEL_CARD_AREA = {
  UNSET_HOTEL_CARD_AREA: 'UNSET_HOTEL_CARD_AREA',
  MAP_PANEL: 'MAP_PANEL',
  RECOMMENDED: 'RECOMMENDED',
};
