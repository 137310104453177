/* eslint-disable import/prefer-default-export */
import scriptLoader from 'saddlebag-scriptloader';

const componentName = 'falcon';
const sharedLibsVersion = '3.3.0';
const reactVersion = '18.3.1';
const reactDomVersion = '18.3.1';

export const loadExternalScripts = () =>
  scriptLoader.queue(
    componentName,
    [
      `/sttc/shared-libs/${sharedLibsVersion}/react/react${reactVersion}.js`,
      `/sttc/shared-libs/${sharedLibsVersion}/react-dom/react-dom${reactDomVersion}.js`,
    ],
    {
      async: false,
      numRetries: 2,
    },
  );
